



















































import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import ApiService from '@/services/api.service';
import LayerBadge from '@/components/LayerBadge.vue';

export default Vue.extend({
  name: 'ProjectStatistics',
  components: {
    VueApexCharts,
    LayerBadge,
  },
  data() {
    return {
      reports: [] as any,
      top5s: {} as any,
      columns: [
        {
          name: 'label',
          label: 'Asset Name',
          field: 'label',
          align: 'left',
        },
        {
          name: 'layer',
          label: 'Layer',
          field: 'layer',
          align: 'center',
        },
        {
          name: 'count',
          label: 'Count',
          field: 'count',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    isCount(report: any) {
      return report.data.length === 1 && report.titles[0] === 'count';
    },
    options(report: any): any {
      return {
        labels: report.titles,
      };
    },
    async loadReports() {
      try {
        const response = await ApiService.get(`/project/${this.$route.params.projectId}/reports`);
        this.reports = response.reports;
        this.top5s = response.top5s;
      } catch {
        this.reports = [];
        this.top5s = {};
      }
    },
  },
  async created() {
    await this.loadReports();
  },
});
